<template>
  <ErrorPage>
    Our advisers can still help you <span class="bold">find the right business finance</span> on a call. Please call us on the number below.
  </ErrorPage>
</template>

<script>
import ErrorPage from '@mic/frontend/views/ErrorPage'

export default {
  name: 'Error',
  components: { ErrorPage }
}
</script>
